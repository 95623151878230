import React from "react";
import { useTranslation } from "react-i18next";


export const Features = (props) => {
	const { i18n, t } = useTranslation();

  return (
    <div id="features" className="text-center"  dir={i18n.language === "ar" ? "rtl" : "ltr"} >
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2   style={{fontFamily: "Almarai", fontSize: "28px" }} >{t("Features.title")} </h2>
        </div>
        <div className="row">
		
                <div key={`${t("Features.first.title")}-${0}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={t("Features.first.icon")}></i>
                  <h3 style={{fontFamily: "Almarai", fontSize: "18px" }}  >{
					t("Features.first.title")
				}</h3>
                  <p>{t("Features.first.text")}</p>
                </div>

				<div key={`${t("Features.second.title")}-${1}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={t("Features.second.icon")}></i>
                  <h3 style={{fontFamily: "Almarai", fontSize: "18px" }} >{
					t("Features.second.title")
				}</h3>
                  <p>{t("Features.second.text")}</p>
                </div>


				<div key={`${t("Features.third.title")}-${2}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={t("Features.third.icon")}></i>
                  <h3 style={{fontFamily: "Almarai", fontSize: "18px" }} >{
					t("Features.third.title")
				}</h3>
                  <p>{t("Features.third.text")}</p>
                </div>



				<div key={`${t("Features.fourth.title")}-${3}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={t("Features.fourth.icon")}></i>
                  <h3 style={{fontFamily: "Almarai", fontSize: "18px" }} >{
					t("Features.fourth.title")
				}</h3>
                  <p>{t("Features.fourth.text")}</p>
                </div>
              
        </div>
      </div>
    </div>
  );
};
