// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       en: {
//         translation: {
// 			"Navigation": {
// 				"title":"Rusl",
// 			   "features": "Features",
// 			   "about": "About",
// 			   "services": "Services",
// 			   "contact": "Contact",
// 			   "language": "Language"
// 			 },
// 			"Header": {
// 			  "title": "We are a Landing Page",
// 			  "paragraph": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sed commodo nibh ante facilisis bibendum."
// 			},
// 			"About": {
// 			  "paragraph": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
// 			  "Why": [
// 				"Lorem ipsum dolor",
// 				"Tempor incididunt",
// 				"Lorem ipsum dolor",
// 				"Incididunt ut labore"
// 			  ],
// 			  "Why2": [
// 				"Aliquip ex ea commodo",
// 				"Lorem ipsum dolor",
// 				"Exercitation ullamco",
// 				"Lorem ipsum dolor"
// 			  ]
// 			},
// 			"Services": [
// 			  {
// 				"icon": "fa fa-wordpress",
// 				"name": "Lorem ipsum dolor",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  },
// 			  {
// 				"icon": "fa fa-cart-arrow-down",
// 				"name": "Consectetur adipiscing",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  },
// 			  {
// 				"icon": "fa fa-cloud-download",
// 				"name": "Lorem ipsum dolor",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  },
// 			  {
// 				"icon": "fa fa-language",
// 				"name": "Consectetur adipiscing",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  },
// 			  {
// 				"icon": "fa fa-plane",
// 				"name": "Lorem ipsum dolor",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  },
// 			  {
// 				"icon": "fa fa-pie-chart",
// 				"name": "Consectetur adipiscing",
// 				"text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at."
// 			  }
// 			],

// 			"Contact": {
// 			  "address": "4321 California St, San Francisco, CA 12345 ",
// 			  "phone": "+1 123 456 1234",
// 			  "email": "info@company.com",
// 			  "facebook": "fb.com",
// 			  "twitter": "twitter.com",
// 			  "youtube": "youtube.com"
// 			},
// 			"Features": [
// 			  {
// 				"icon": "fa fa-comments-o",
// 				"title": "Lorem ipsum",
// 				"text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
// 			  },
// 			  {
// 				"icon": "fa fa-bullhorn",
// 				"title": "Lorem ipsum",
// 				"text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
// 			  },
// 			  {
// 				"icon": "fa fa-group",
// 				"title": "Lorem ipsum",
// 				"text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
// 			  },
// 			  {
// 				"icon": "fa fa-magic",
// 				"title": "Lorem ipsum",
// 				"text": "Lorem ipsum dolor sit amet placerat facilisis felis mi in tempus eleifend pellentesque natoque etiam."
// 			  }
// 			]
// 		  }
//       },
//       ar: {
//         translation: {
// 			"Navigation": {
// 				"title":"رسل",
// 				  "home": "الصفحة الرئيسية",
// 				  "about": "من نحن",
// 				  "services": "الخدمات",
// 				  "contact": "اتصل بنا"
// 				},
// 			"Header": {
// 			  "title": "نحن صفحة هبوط مميزة",
// 			  "paragraph": "مرحباً بكم في موقعنا المتميز. نقدم لكم أفضل الخدمات وأحدث التقنيات لتلبية احتياجاتكم ومتطلباتكم بأعلى جودة وكفاءة"
// 			},
// 			"About": {
// 			  "paragraph": "نحن شركة رائدة في مجال تقديم الخدمات الرقمية والحلول التقنية المبتكرة. نسعى دائماً لتقديم أفضل الحلول التي تلبي احتياجات عملائنا وتساعدهم على النمو والتطور في عالم الأعمال الرقمي. نعمل بشغف وإبداع لتحقيق رؤيتنا في أن نكون الخيار الأول والأفضل لعملائنا",
// 			  "Why": [
// 				"خبرة متميزة",
// 				"جودة عالية",
// 				"دعم متواصل",
// 				"أسعار تنافسية"
// 			  ],
// 			  "Why2": [
// 				"حلول مبتكرة",
// 				"فريق محترف",
// 				"تقنيات حديثة",
// 				"نتائج مضمونة"
// 			  ]
// 			},
// 			"Services": [
// 			  {
// 				"icon": "fa fa-wordpress",
// 				"name": "تطوير المواقع",
// 				"text": "نقدم خدمات تطوير المواقع الإلكترونية باستخدام أحدث التقنيات والأدوات، مع التركيز على تجربة المستخدم وسهولة الاستخدام"
// 			  },
// 			  {
// 				"icon": "fa fa-cart-arrow-down",
// 				"name": "التجارة الإلكترونية",
// 				"text": "حلول متكاملة للتجارة الإلكترونية تشمل تطوير المتاجر الإلكترونية وإدارة المنتجات وأنظمة الدفع الآمنة"
// 			  },
// 			  {
// 				"icon": "fa fa-cloud-download",
// 				"name": "الحوسبة السحابية",
// 				"text": "خدمات الحوسبة السحابية المتكاملة مع حلول التخزين الآمن والنسخ الاحتياطي وإدارة البيانات"
// 			  },
// 			  {
// 				"icon": "fa fa-language",
// 				"name": "خدمات الترجمة",
// 				"text": "خدمات ترجمة احترافية للمواقع والتطبيقات بمختلف اللغات مع مراعاة خصوصية كل لغة وثقافة"
// 			  },
// 			  {
// 				"icon": "fa fa-plane",
// 				"name": "حلول متنقلة",
// 				"text": "تطوير تطبيقات الهواتف الذكية وحلول التنقل الذكية لتلبية احتياجات عملائنا في عصر التكنولوجيا المتنقلة"
// 			  },
// 			  {
// 				"icon": "fa fa-pie-chart",
// 				"name": "تحليل البيانات",
// 				"text": "خدمات تحليل البيانات وإعداد التقارير الإحصائية لمساعدة عملائنا في اتخاذ القرارات المبنية على البيانات"
// 			  }
// 			],
// 			"Contact": {
// 			  "address": "٤٣٢١ شارع كاليفورنيا، سان فرانسيسكو، كاليفورنيا ١٢٣٤٥",
// 			  "phone": "+١ ١٢٣ ٤٥٦ ١٢٣٤",
// 			  "email": "info@company.com",
// 			  "facebook": "fb.com",
// 			  "twitter": "twitter.com",
// 			  "youtube": "youtube.com"
// 			},
// 			"Features": [
// 			  {
// 				"icon": "fa fa-comments-o",
// 				"title": "دعم متواصل",
// 				"text": "نقدم دعماً فنياً متواصلاً على مدار الساعة لضمان استمرارية أعمالكم وحل أي مشكلات قد تواجهكم"
// 			  },
// 			  {
// 				"icon": "fa fa-bullhorn",
// 				"title": "تسويق رقمي",
// 				"text": "خدمات التسويق الرقمي المتكاملة لتعزيز تواجدكم على الإنترنت وزيادة وصولكم إلى عملائكم المستهدفين"
// 			  },
// 			  {
// 				"icon": "fa fa-group",
// 				"title": "فريق محترف",
// 				"text": "فريق عمل محترف من الخبراء والمتخصصين في مختلف المجالات التقنية لتقديم أفضل الحلول"
// 			  },
// 			  {
// 				"icon": "fa fa-magic",
// 				"title": "تصميم إبداعي",
// 				"text": "تصاميم إبداعية وعصرية تجمع بين الجمال والوظيفة لتقديم تجربة مستخدم فريدة ومميزة"
// 			  }
// 			]
// 		  }
//       }
//     },
//     fallbackLng: 'ar',
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import ar from "../locales/ar.json";

const LangeParDefaut = localStorage.getItem("languegChanger");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
  },
  lng: LangeParDefaut || "ar",
  fallbackLng: "ar",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
