import React from "react";
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
export const Header = (props) => {
	const {i18n, t } = useTranslation();


  return (
    <header id="header"  style={i18n.language === "ar" ? { fontFamily: "Almarai"} : { fontFamily: "Almarai"} } dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text"  >
                <h1 style={{fontFamily: "Almarai", fontSize: "38px", fontWeight: "bolder" }} >
		
				{t("Header.title")}
                  <span></span>
				<br />
				<br />
				

                </h1>
                <p style={{fontFamily: "Almarai", fontSize: "20px" }} >
				{t("Header.paragraph")}

					</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
				  style={{fontFamily: "Almarai", fontSize: "16px" }}
                >
				
				{t("Header.learn")}

                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
