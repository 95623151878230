import React from "react";
import { useTranslation } from "react-i18next";

export const Navigation = () => {
  const { i18n, t } = useTranslation();

  function onLanguageChange(lng) {
    console.log(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("languegChanger", lng);
  }

  return (
    <nav
	
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
    >
      <div className="container"  >
        <div className="navbar-header" >
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top" style={{fontFamily: "Almarai", fontSize: "32px" }} >
		
		{t("Navigation.title")}
			
			</a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1" 
		  
        >
          <ul className="nav navbar-nav navbar-right" style={
		i18n.language === "ar" ? { fontFamily: "Almarai" ,fontSize: "20px"} : { fontFamily: "Almarai"} } dir={i18n.language === "ar" ? "rtl" : "ltr"}>
            <li>
              <a href="#features" className="page-scroll" style={{fontFamily: "Almarai"}} >
       
                {t("Navigation.services")}

				
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll"style={{fontFamily: "Almarai"}} >
                {t("Navigation.about")}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll"style={{fontFamily: "Almarai"}} >
			  {t("Navigation.services")} 
              </a>
            </li>

            <li>
              <a href="#contact" className="page-scroll" style={{fontFamily: "Almarai"}} >
			  {t("Navigation.contact")} 
              </a>
            </li>

            <li>
              
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};


//<select
//value={i18n.language}
//onChange={(e) => onLanguageChange(e.target.value)}
//>
//<option value="en">English</option>
//<option value="ar">Arabic</option>
//</select>