import { useTranslation } from "react-i18next";
import React from "react";

export const About = (props) => {
	const { i18n, t } = useTranslation();

  return (
    <div id="about" >
      <div style={i18n.language === "ar" ? { fontFamily: "Almarai"} : { fontFamily: "Almarai"} } className="container" dir={i18n.language === "ar" ? "rtl" : "ltr"} >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{fontFamily: "Almarai", fontSize: "32px" }} >{t("Navigation.about")}</h2>
              <p>{t("About.paragraph")}</p>
              <h3 style={{fontFamily: "Almarai", fontSize: "18px" }} >{t("About.whyUs")}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                   
					   <li key={`${t("About.Why.first")}-${1}`}> {t("About.Why.first")}  </li> 
					   <li key={`${t("About.Why.second")}-${2}`}> {t("About.Why.second")}  </li> 
					   <li key={`${t("About.Why.third")}-${3}`}> {t("About.Why.third")}  </li> 
					   <li key={`${t("About.Why.fourth")}-${4}`}> {t("About.Why.fourth")}  </li> 

                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
				  <li key={`${t("About.Why.first")}-${1}`}> {t("About.Why.first")}  </li> 
					   <li key={`${t("About.Why.second")}-${2}`}> {t("About.Why.second")}  </li> 
					   <li key={`${t("About.Why.third")}-${3}`}> {t("About.Why.third")}  </li> 
					   <li key={`${t("About.Why.fourth")}-${4}`}> {t("About.Why.fourth")}  </li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
