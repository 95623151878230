import React from "react";
import { useTranslation } from "react-i18next";


export const Services = (props) => {
	const { i18n, t } = useTranslation();
  return (
    <div id="services" className="text-center"  style={  i18n.language === "ar" ? { fontFamily: "Almarai"} : { fontFamily: "Almarai"} } dir={i18n.language === "ar" ? "rtl" : "ltr"} >
      <div className="container">
        <div className="section-title">
          <h2 style={{fontFamily: "Almarai", fontSize: "28px" }} > {t("Navigation.services")}</h2>
          <p>
            
          </p>
        </div>
        <div className="row">
        <div key={`${t("Services.first.name")}-${1}`} className="col-md-4">
                  {" "}
                  <i className={t("Services.first.icon")}></i>
                  <div className="service-desc">
                    <h3 style={{fontFamily: "Almarai", fontSize: "22px" }} >{t("Services.first.name")}</h3>
                    <p>{t("Services.first.text")}</p>
                  </div>
                </div>
				<div key={`${t("Services.second.name")}-${2}`} className="col-md-4">
                  {" "}
                  <i className={t("Services.second.icon")}></i>
                  <div className="service-desc">
                    <h3 style={{fontFamily: "Almarai", fontSize: "22px" }} >{t("Services.second.name")}</h3>
                    <p>{t("Services.second.text")}</p>
                  </div>
                </div>
				<div key={`${t("Services.third.name")}-${3}`} className="col-md-4">
                  {" "}
                  <i className={t("Services.third.icon")}></i>
                  <div className="service-desc">
                    <h3 style={{fontFamily: "Almarai", fontSize: "22px" }} >{t("Services.third.name")}</h3>
                    <p>{t("Services.third.text")}</p>
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
};
